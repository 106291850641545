import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyB75lVU-QIJhJ_DS4CVA5AoNxkkaASr1M4",
  authDomain: "mirwn-6cdef.firebaseapp.com",
  projectId: "mirwn-6cdef",
  storageBucket: "mirwn-6cdef.appspot.com",
  messagingSenderId: "640088765355",
  appId: "1:640088765355:web:55f55f22788e3148a918ee",
  measurementId: "G-KQTVZY0E19"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
